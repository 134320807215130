@import "npm:yet-another-react-lightbox/styles.css";

:root {
  --pdp-image-preview-size: 28px;
  --pdp-image-preview-backdrop-color: var(--color-backgroundOverlay);
}

@media (--mq-is-mobile) {
  :root {
    --pdp-image-preview-backdrop-color: #000000f2;
  }
}

@keyframes progressAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media (--mq-is-mobile) {
    gap: 12px;
  }
}

.currentMedia {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-basis: 0;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;

  &.zoomable {
    cursor: zoom-in;
  }

  @media (--mq-is-mobile) {
    height: auto;
    max-height: 70vh;
    overflow: initial;
  }

  > img,
  > picture,
  > picture > img,
  > .video > video {
    height: 100%;
    min-height: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 1;
    position: relative;
    transition: filter 0.2s ease;
    width: 100%;
  }

  &.isLoading {
    > img,
    > picture,
    > picture > img,
    > .video > video {
      opacity: 0;
    }
  }
}

.loader {
  display: none;
  z-index: 1;

  @media (--mq-is-mobile) {
    color: var(--color-commonWhite);
  }

  &.isLoading {
    display: flex;
  }
}

.currentMedia .videoControls,
.currentMediaFullScreen .videoControls {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  left: 0;
  opacity: 0;
  padding: 0 0 24px 32px;
  position: absolute;
  transition: opacity 0.3s ease;

  @media (--mq-is-mobile) {
    padding-bottom: 40px;
  }

  &.visible {
    opacity: 1;
  }

  & > button {
    background-color: var(--color-SolidNeutralGray5);
    border: none;
    border-radius: 50%;
    color: var(--color-commonWhite);
    cursor: pointer;
    height: 32px;
    margin: 0;
    opacity: 0.9;
    outline: none;
    padding: 0;
    width: 32px;

    &:hover {
      background-color: var(--color-SolidNeutralGray3);
    }

    &:active {
      background-color: var(--color-SolidNeutralGray1);
    }
  }
}

.currentMedia > .video,
.currentMediaFullScreen .video {
  display: inline-block;
  overflow: hidden;
  position: relative;

  &:hover {
    & > .videoControls {
      opacity: 1;
    }
  }
}

.currentMedia > .soldOutBanner {
  border-radius: 42px;
  box-sizing: border-box;
  cursor: default;
  gap: 4px;
  left: 50%;
  opacity: 0;
  padding: 4px 13px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease, visibility 0s 0.2s;
  visibility: hidden;
  width: fit-content;
  z-index: 1;
}

.currentMedia.isSoldOut:not(:hover) {
  > .soldOutBanner {
    opacity: 1;
    transition: opacity 0.2s ease, visibility 0s;
    visibility: visible;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  > img,
  > picture,
  > picture > img,
  > .video > video {
    filter: opacity(0.9) brightness(0.6);
  }
}

.iframe {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  @media (--mq-is-mobile) {
    min-height: 50vh;
  }

  & > iframe {
    border: none;
    flex-basis: 0;
    flex-grow: 1;
  }

  & > div {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.currentMediaFullScreen:global(.yarl__root) {
  --yarl__color_backdrop: var(--pdp-image-preview-backdrop-color);

  & :global(.yarl__slide) {
    --yarl__carousel_padding_px: 0;
  }

  & :global(.yarl__toolbar) {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    right: 0;
    padding: 24px 32px 0 0;
    position: absolute;
    top: 0;

    @media (--mq-is-mobile) {
      padding: 40px 24px 0 0;
    }
  }
}

.paginationContainer {
  align-items: flex-start;
}

.pagination {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;

  @media (--mq-is-mobile) {
    gap: 7px;
  }
}

.paginationItem {
  background-color: transparent;
  border: none;
  opacity: 0.5;
  padding: 0;
  transition: opacity 0.3s ease;

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  & > .preview {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 0;
    height: var(--pdp-image-preview-size);
    width: var(--pdp-image-preview-size);
  }

  & > .indicator {
    background-color: var(--color-SolidNeutralGray1);
    border-left: solid var(--color-commonBlack);
    border-left-width: 0px;
    box-sizing: border-box;
    display: block;
    height: 3px;
    margin-top: 3px;
    opacity: 0;
    transition: opacity 0.5s step-start;
    width: var(--pdp-image-preview-size);
  }

  &.current {
    cursor: default;
    opacity: 1;

    & > .indicator {
      opacity: 1;
    }

    &:not(.isAutoSwiping) > .indicator {
      opacity: 0;
    }
  }
}

@media (--mq-is-mobile) {
  .paginationContainer:not(.forceThumbnails) {
    --pdp-image-preview-size: 36px;

    align-items: center;

    & > .pagination > .paginationItem {
      opacity: 1;
      width: fit-content;

      & > .preview {
        display: none;
      }

      & > .indicator {
        background-color: var(--color-SolidNeutralGray5);
        border-left-color: var(--color-SolidNeutralGray5);
        border-radius: 100px;
        height: 5px;
        opacity: 1;
        width: 5px;
      }

      &.current > .indicator {
        background-color: var(--color-SolidNeutralGray1);
        height: 5px;
      }

      &.current.isAutoSwiping > .indicator {
        border-radius: 0;
        width: var(--pdp-image-preview-size);
      }

      &.current:not(.isAutoSwiping) > .indicator{
        border-radius: 100px;
        opacity: 1;
        width: 5px;
      }
    }
  }
}

.actionButton {
  --actionButtonColor: var(--color-commonBlack);
  --actionButtonColor-active: var(--color-SolidNeutralGray1);
  --actionButtonColor-hover: var(--color-SolidNeutralGray3);
  background-color: var(--color-backgroundDefault);
  border-color: var(--color-backgroundDefault);
  padding-left: 14px;
  padding-right: 16px;
  width: initial;

  &:hover {
    border-color: var(--color-backgroundDefault);
  }

  &:active {
    border-color: var(--color-backgroundDefault);
  }
}
