import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGateValue } from '@statsig/react-bindings';

import { joinClasses, useRefState } from '@mp-frontend/core-utils';

import ExploreArtistsPaginatedFragmentType, {
  ExploreArtistsPaginatedFragment$data,
  ExploreArtistsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreArtistsPaginatedFragment.graphql';
import ExploreArtworksPaginatedFragmentType, {
  ExploreArtworksPaginatedFragment$key,
} from 'graphql/__generated__/ExploreArtworksPaginatedFragment.graphql';
import ExploreEditorialsPaginatedFragmentType, {
  ExploreEditorialsPaginatedFragment$data,
  ExploreEditorialsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreEditorialsPaginatedFragment.graphql';
import ExploreExhibitionsPaginatedFragmentType, {
  ExploreExhibitionsPaginatedFragment$data,
  ExploreExhibitionsPaginatedFragment$key,
} from 'graphql/__generated__/ExploreExhibitionsPaginatedFragment.graphql';
import ExploreSeriesPaginatedFragmentType, {
  ExploreSeriesPaginatedFragment$data,
  ExploreSeriesPaginatedFragment$key,
} from 'graphql/__generated__/ExploreSeriesPaginatedFragment.graphql';
import { NFTsProductCardFragment$data } from 'graphql/__generated__/NFTsProductCardFragment.graphql';
import PaginatedExploreArtistsQueryType, {
  PaginatedExploreArtistsQuery,
} from 'graphql/__generated__/PaginatedExploreArtistsQuery.graphql';
import PaginatedExploreArtworksQueryType, {
  PaginatedExploreArtworksQuery,
} from 'graphql/__generated__/PaginatedExploreArtworksQuery.graphql';
import PaginatedExploreEditorialsQueryType, {
  PaginatedExploreEditorialsQuery,
} from 'graphql/__generated__/PaginatedExploreEditorialsQuery.graphql';
import PaginatedExploreExhibitionsQueryType, {
  PaginatedExploreExhibitionsQuery,
} from 'graphql/__generated__/PaginatedExploreExhibitionsQuery.graphql';
import PaginatedExploreSeriesQueryType, {
  PaginatedExploreSeriesQuery,
} from 'graphql/__generated__/PaginatedExploreSeriesQuery.graphql';

import ArtworkCard from 'components/cards/ArtworkCard';
import EditorialCard from 'components/cards/EditorialCard';
import ExhibitionCard from 'components/cards/ExhibitionCard';
import SeriesCard from 'components/cards/SeriesCard';
import UserCard from 'components/cards/UserCard';
import DefaultErrorBoundary from 'components/ErrorBoundaries/DefaultErrorBoundary';
import { GrouppedLoadingProvider } from 'components/GrouppedLoadingProvider';
import SearchInput from 'components/input/SearchInput';
import { TrackingContext } from 'components/trackingContext';
import { APP_NAME } from 'constants/Utils';
import { EcommerceSourceType } from 'GTM';
import { ExploreType } from 'pages/explore/types';
import CSSGap from 'types/enums/css/Gap';
import CSSGlobal from 'types/enums/css/Global';
import CSSMargin from 'types/enums/css/Margin';
import setDocTitle from 'utils/setDocTitle';

import SearchCarousel from './SearchCarousel';

import * as styles from 'css/pages/search/SearchPage.module.css';

const QUERY_KEY = 'q';
const TITLE = 'Search';
const DEBOUNCE_TIME_MS = 1000;

function SearchPage() {
  const showExploreSeries = useGateValue('gate_explore_series');
  const navigate = useNavigate();
  const location = useLocation();
  const [localQuery, setLocalQuery] = useState<string>('');
  const [, setInputRef] = useRefState<HTMLInputElement>(
    null,
    (ref: HTMLInputElement) => ref?.focus()
  );

  const query = useMemo(
    () => new URLSearchParams(location.search).get(QUERY_KEY) ?? '',
    [location.search]
  );

  useEffect(() => {
    setDocTitle([TITLE, APP_NAME].join(' | '));
    setLocalQuery(query);
  }, [query]);

  const handleSubmit = useCallback(
    (value: string) => {
      const search = new URLSearchParams();
      if (value) search.set(QUERY_KEY, value);

      navigate({ search: search.toString() });
    },
    [navigate]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSubmit = useCallback(
    debounce(handleSubmit, DEBOUNCE_TIME_MS),
    []
  );

  const handleSetValue = useCallback(
    (value: string) => {
      setLocalQuery(value);
      debouncedHandleSubmit(value.trim());
    },
    [debouncedHandleSubmit]
  );

  return (
    <TrackingContext source={EcommerceSourceType.Search}>
      <div className={joinClasses(CSSGlobal.Cursor.Default, styles.searchPage)}>
        <div
          className={joinClasses(
            CSSGlobal.Flex.CenteredCol,
            CSSGap[24],
            CSSMargin.BOTTOM[24],
            styles.searchInputContainer
          )}
        >
          <SearchInput
            className={styles.searchInput}
            ref={setInputRef}
            value={localQuery}
            variant="light"
            setValue={handleSetValue}
            onChange={handleSubmit}
          />
        </div>

        <div className={styles.content}>
          <DefaultErrorBoundary hideState>
            <GrouppedLoadingProvider>
              <SearchCarousel<
                PaginatedExploreArtworksQuery,
                ExploreArtworksPaginatedFragment$key,
                NFTsProductCardFragment$data
              >
                exploreType={ExploreType.Artworks}
                search={query}
                fragmentConcreteRequest={ExploreArtworksPaginatedFragmentType}
                queryConcreteRequest={PaginatedExploreArtworksQueryType}
                renderElement={(element, className) => (
                  <ArtworkCard
                    key={element.id}
                    className={className}
                    nft={element}
                  />
                )}
              />

              <SearchCarousel<
                PaginatedExploreExhibitionsQuery,
                ExploreExhibitionsPaginatedFragment$key,
                ExploreExhibitionsPaginatedFragment$data['exhibition']['results']['edges'][number]['node']
              >
                exploreType={ExploreType.Exhibitions}
                search={query}
                fragmentConcreteRequest={
                  ExploreExhibitionsPaginatedFragmentType
                }
                queryConcreteRequest={PaginatedExploreExhibitionsQueryType}
                renderElement={(element, className) => (
                  <ExhibitionCard
                    key={element.id}
                    className={className}
                    exhibition={element}
                  />
                )}
              />

              <SearchCarousel<
                PaginatedExploreArtistsQuery,
                ExploreArtistsPaginatedFragment$key,
                ExploreArtistsPaginatedFragment$data['artist']['results']['edges'][number]['node']
              >
                exploreType={ExploreType.Artists}
                search={query}
                fragmentConcreteRequest={ExploreArtistsPaginatedFragmentType}
                queryConcreteRequest={PaginatedExploreArtistsQueryType}
                renderElement={(element, className) => (
                  <UserCard
                    key={element.id}
                    className={className}
                    user={element}
                    biography={element.biography}
                    createdArtworks={element.createdArtworks}
                    store={element.store}
                  />
                )}
              />

              <SearchCarousel<
                PaginatedExploreEditorialsQuery,
                ExploreEditorialsPaginatedFragment$key,
                ExploreEditorialsPaginatedFragment$data['editorial']['results']['edges'][number]['node']
              >
                exploreType={ExploreType.Editorial}
                search={query}
                fragmentConcreteRequest={ExploreEditorialsPaginatedFragmentType}
                queryConcreteRequest={PaginatedExploreEditorialsQueryType}
                renderElement={(element, className) => (
                  <EditorialCard
                    key={element.id}
                    className={className}
                    editorial={element}
                  />
                )}
              />

              {!!showExploreSeries && (
                <SearchCarousel<
                  PaginatedExploreSeriesQuery,
                  ExploreSeriesPaginatedFragment$key,
                  ExploreSeriesPaginatedFragment$data['series']['results']['edges'][number]['node']
                >
                  exploreType={ExploreType.Series}
                  search={query}
                  fragmentConcreteRequest={ExploreSeriesPaginatedFragmentType}
                  queryConcreteRequest={PaginatedExploreSeriesQueryType}
                  renderElement={(element, className) => (
                    <SeriesCard
                      key={element.id}
                      className={className}
                      series={element}
                    />
                  )}
                />
              )}
            </GrouppedLoadingProvider>
          </DefaultErrorBoundary>
        </div>
      </div>
    </TrackingContext>
  );
}

export default SearchPage;
